// @ts-ignore @todo, convert api implementation into typescript
import { get, post, put } from '@/application/api/implementations/app';
// @ts-ignore @todo, convert api implementation into typescript
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';
import { AxiosPromise } from 'axios';

interface CompanyEntity {
  id: number,
  name: string,
  chamberOfCommerceNumber: string,
  vatNumber: string,
  street: string,
  houseNumber: string,
  zipCode: string,
  city: string,
  phone: string,
  iban: string,
  ibanAscription: string,
  bicCode: string,
  temporaryWorkerCostPriceFactor: number,
  logo: Record<string, unknown>
}

export const show = (entityId: number): AxiosPromise => get(`entity/${entityId}`);
export const update = (companyEntity: CompanyEntity): AxiosPromise => put(`entity/${companyEntity.id}`, companyEntity);

export const index = (page: number, perPage: number, search: string, sortBy: string, descending: boolean, params: Record<string, unknown>): AxiosPromise => {
  return getPaginated('entity', page, perPage, search, sortBy, descending, params);
};

export const create = (companyEntity: CompanyEntity): AxiosPromise => {
  return post('entity', companyEntity);
};
