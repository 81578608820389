<template>
  <PageLayout>
    <template #header>
      <k-title>
        <slot name="header" />
      </k-title>
    </template>
    <template #flow-actions>
      <slot name="flow-actions" />
    </template>
    <template #default>
      <slot
        :attrs="{ value: isFilterbarOpen, filters }"
        :on="{ 'update:filters': handleFiltersUpdate, input: handleFilterInput }"
        name="filters"
      />

      <v-row
        align="center"
        class="my-4"
        no-gutters
      >
        <v-col
          v-if="withSearch || withFilters"
          class="searchbar"
        >
          <v-row
            align="center"
            no-gutters
          >
            <v-expand-x-transition v-if="withSearch">
              <v-form
                v-if="isSearchbarOpen"
                @submit.prevent="performSearch"
              >
                <VTextField
                  v-model="searchQuery"
                  autofocus
                  class="mr-3"
                  dense
                  filled
                  hide-details
                  clearable
                  :placeholder="$t('actions.search')"
                  @click:clear="clearSearch"
                />
              </v-form>
            </v-expand-x-transition>
            <v-btn
              v-if="withSearch"
              icon
              height="35"
              class="mr-2"
              @click="toggleSearchbar"
            >
              <v-icon color="grey darken-1">
                $search
              </v-icon>
            </v-btn>
            <v-btn
              v-if="withFilters"
              icon
              class=""
              height="35"
              @click="toggleFilterbar"
            >
              <v-badge
                :value="hasFilters"
                color="info"
                dot
              >
                <v-icon color="grey darken-1">
                  $filter
                </v-icon>
              </v-badge>
            </v-btn>
          </v-row>
        </v-col>
        <VSpacer />
        <v-btn-toggle
          v-if="viewSlots.length > 1"
          v-model="view"
          @change="handleViewChange"
        >
          <v-btn
            v-for="slot in viewSlots"
            :key="slot"
            icon
          >
            <v-icon color="grey darken-1">
              {{ viewIcons[slot.substr(5)] || '$viewList' }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>
      <v-card
        class="px-4 mt-3 k-transparent"
        flat
      >
        <v-card-text class="px-0">
          <v-window v-model="view">
            <v-window-item
              v-for="slot in viewSlots"
              :key="slot"
            >
              <slot :name="slot" />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </template>
  </PageLayout>
</template>

<script>
import KTitle from '@/components/layout/KTitle.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KCrudLayout',
  components: {
    KTitle,
    PageLayout,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    viewIcons: {
      type: Object,
      default: () => ({}),
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
    searchByDefault: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    view: 0,
    isSearchbarOpen: false,
    searchQuery: '',
    isFilterbarOpen: false,
  }),
  computed: {
    viewSlots() {
      return Object.keys(this.$slots).filter((slot) => slot.substr(0, 5) === 'view.');
    },
    withFilters() {
      return this.$scopedSlots.filters;
    },
    hasFilters() {
      return Object.values(this.filters).some(val => !this.isEmpty(val));
    },
  },
  created() {
    this.view = this.getSelectedCrudLayoutView()(this.$route.name);
    if (this.searchByDefault){
      this.openSearchbar();
    }
    const savedSearchQueries = JSON.parse(window.sessionStorage.getItem('searchQueries'));

    if (savedSearchQueries && savedSearchQueries[this.$route.name]){
      this.searchQuery = savedSearchQueries[this.$route.name];
      this.$emit('update:search', this.searchQuery);
    }
  },
  methods: {
    ...mapMutations('settings', ['setCrudLayoutView']),
    ...mapGetters('settings', ['getSelectedCrudLayoutView']),
    performSearch() {
      this.isSearchbarOpen = this.searchByDefault;
      
      window.sessionStorage.setItem('searchQueries', JSON.stringify({
        ...JSON.parse(window.sessionStorage.getItem('searchQueries')),
        [this.$route.name]: this.searchQuery,
      }));
      
      this.$emit('update:search', this.searchQuery);
    },
    openSearchbar() {
      this.isSearchbarOpen = true;
    },
    clearSearch() {
      this.searchQuery = '';
      this.performSearch();
    },
    toggleSearchbar() {
      this.isSearchbarOpen ? this.performSearch() : this.openSearchbar();
    },
    handleViewChange(view) {
      this.setCrudLayoutView({
        routeName: this.$route.name,
        selectedView: view,
      });
    },
    toggleFilterbar(to = null) {
      this.isFilterbarOpen = to === null ? !this.isFilterbarOpen : to;
    },
    handleFiltersUpdate(event) {
      return this.$emit('update:filters', event);
    },
    handleFilterInput(event) {
      return this.toggleFilterbar(event);
    },
    // @TODO: refactor to use the isEmpty util
    isEmpty(val) {
      if (val === null) return true;
      if (typeof val === 'object') return Object.values(val).every((nested) => this.isEmpty(nested));
      if (Array.isArray(val)) return val.length === 0;
      return !val;
    },
  },
};
</script>

<style scoped>
.searchbar {
  width: 350px;
}
</style>
