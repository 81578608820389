
import Vue from 'vue';
import CompanyEntity from '@/application/models/CompanyEntity';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import { create, index, update, show } from '@/modules/companyEntity/api/companyEntities';
import { DataTableHeader } from 'vuetify';
import eventBus from '@/application/eventBus';

import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import CompanyEntityForm from '@/modules/companyEntity/components/CompanyEntityForm.vue';
import { translateField } from '@/application/util/translation';
import { AxiosPromise } from 'axios';

interface ComponentData {
  searchQuery: string,
  createDialog: boolean,
  updateDialog: boolean,
  createFormValues: CompanyEntity,
  updateFormValues: CompanyEntity,
}

interface Header extends Omit<DataTableHeader, 'text'> {
  text?: string,
  language?: string
}

export default Vue.extend({
  name: 'CompanyEntities',
  components: {
    KCrudTable,
    CompanyEntityForm,
    KCrudLayout,
  },
  data: (): ComponentData => ({
    searchQuery: '',
    createDialog: false,
    updateDialog: false,
    createFormValues: new CompanyEntity(),
    updateFormValues: new CompanyEntity(),
  }),
  computed: {
    crudHeaders(): Header[] {
      return [
        {
          value: 'name',
          text: translateField('name', 'entity.fields'),
        },
        {
          value: 'city',
          text: translateField('city', 'entity.fields'),
        },
        {
          value: 'chamberOfCommerceNumber',
          text: translateField('chamberOfCommerceNumber', 'entity.fields'),
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: this.$route.name },
        // @ts-ignore @todo, create typescript function for $tc
        text: this.$tc('entity.title', 2),
      },
    ]);
  },
  methods: {
    indexRequest(...args: any[]) {
      // @ts-ignore @todo: fix ...args
      return index(...args);
    },
    createRequest(): AxiosPromise {
      return create(this.createFormValues);
    },
    updateRequest(): AxiosPromise {
      return update(this.updateFormValues);
    },
    handleOpenCreateDialog(): void {
      this.createFormValues = new CompanyEntity();
      this.createDialog = true;
    },
    async handleOpenUpdate(item: CompanyEntity) {
      this.updateFormValues = new CompanyEntity();
      const response = await show(item.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
});
