
import Vue from 'vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import { translateField } from '@/application/util/translation';
import validChamberOfCommerceNumber from '@/application/util/inputFieldRules/validChamberOfCommerceNumber';

interface Panel {
  name: string
}

interface ComponentData {
  panels: Panel[]
}

export default Vue.extend({
  name: 'CompanyEntityForm',
  components: {
    KFileField,
    KTextField,
    KFormDialog,
    KFieldGroup,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
  },
  data: (): ComponentData => ({
    panels: [
      { name: translateField('default', 'entity.tabs') },
      { name: translateField('financial', 'entity.tabs') },
    ],
  }),
  methods: {
    validChamberOfCommerceNumber,

  },
});
