import Model from '@/application/models/ts/model';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class CompanyEntity extends Model  {
  id = 0;
  name = '';
  chamberOfCommerceNumber = '';
  vatNumber = '';
  street = '';
  houseNumber = '';
  zipCode = '';
  city = '';
  phone = '';
  iban = '';
  ibanAscription = '';
  bicCode = '';
  temporaryWorkerCostPriceFactor = null;
  logo = null;

  mapForRequest():Record<string, any> {
    return {
      ...this,
      logo: mapFileForRequest(this.logo),
      temporaryWorkerCostPriceFactor: Math.round((this.temporaryWorkerCostPriceFactor ?? 1) * 10000),
    };
  }

  mapResponse(data: Record<string, any>): Record<string, any> {
    return super.mapResponse({
      ...data,
      temporaryWorkerCostPriceFactor: data.temporaryWorkerCostPriceFactor / 10000,
    });
  }

}

export default CompanyEntity;
